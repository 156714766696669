define("discourse/plugins/discourse-private-replies/discourse/templates/connectors/topic-above-posts/privatereplies", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.model.private_replies}}
  <div class="row">
    <div class="post-notice custom">
      {{d-icon 'user-secret'}}
      <div>
        <p>{{i18n 'private_replies.topic_banner_line_1'}}</p>
        {{#if (eq this.siteSettings.private_replies_topic_starter_primary_group_can_see_all true)}}
          <p>{{i18n 'private_replies.topic_banner_line_2_also_group'}}</p>
        {{else}}
          <p>{{i18n 'private_replies.topic_banner_line_2'}}</p>
        {{/if}}
      </div>
    </div>
  </div>
  {{/if}}
  
  */
  {
    "id": "hVP8bSY3",
    "block": "[[[41,[30,0,[\"model\",\"private_replies\"]],[[[10,0],[14,0,\"row\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"post-notice custom\"],[12],[1,\"\\n    \"],[1,[28,[35,2],[\"user-secret\"],null]],[1,\"\\n    \"],[10,0],[12],[1,\"\\n      \"],[10,2],[12],[1,[28,[35,4],[\"private_replies.topic_banner_line_1\"],null]],[13],[1,\"\\n\"],[41,[28,[37,5],[[30,0,[\"siteSettings\",\"private_replies_topic_starter_primary_group_can_see_all\"]],true],null],[[[1,\"        \"],[10,2],[12],[1,[28,[35,4],[\"private_replies.topic_banner_line_2_also_group\"],null]],[13],[1,\"\\n\"]],[]],[[[1,\"        \"],[10,2],[12],[1,[28,[35,4],[\"private_replies.topic_banner_line_2\"],null]],[13],[1,\"\\n\"]],[]]],[1,\"    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"div\",\"d-icon\",\"p\",\"i18n\",\"eq\"]]",
    "moduleName": "discourse/plugins/discourse-private-replies/discourse/templates/connectors/topic-above-posts/privatereplies.hbs",
    "isStrictMode": false
  });
});